import type { WidgetProps } from "widgets/BaseWidget";
import type { InputType } from "widgets/InputWidget/constants";
import { InputTypes, JSONFormInputFieldDefaultWidth } from "./constants";

function isInputTypeSingleLineOrMultiLine(inputType: InputType) {
  return (
    inputType === InputTypes.MULTI_LINE_TEXT || inputType === InputTypes.TEXT
  );
}

export function checkInputTypeTextByProps(props: WidgetProps) {
  return isInputTypeSingleLineOrMultiLine(props.inputType);
}

export function checkInputTypeText(inputType: InputType) {
  return isInputTypeSingleLineOrMultiLine(inputType);
}

export const adjustWidthBasedOnContent = (
  inputElement: HTMLInputElement | HTMLTextAreaElement,
) => {
  const numberOfCharacters = inputElement.value.length;
  if (numberOfCharacters >= 20) {
    const length = numberOfCharacters + "ch";
    inputElement.style.width = length;
  } else if (numberOfCharacters == 0) {
    inputElement.style.width = `${JSONFormInputFieldDefaultWidth}px`;
  }
};
